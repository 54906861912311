// import logo from "./logo.svg";
import "./App.css";

import React, {useState, useEffect} from "react";
// import {BrowserRouter, Routes, Route} from "react-router-dom";
// import Toppage from "./container/Toppage";
import {styled} from "@mui/material/styles";
import {ThemeProvider, createTheme} from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
// import Card from "@mui/material/Card";
// import CardActionArea from "@mui/material/CardActionArea";
import CssBaseline from "@mui/material/CssBaseline";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import {auth} from "./firebase";
import {
  GoogleAuthProvider,
  TwitterAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  signInWithRedirect,
  signOut,
} from "firebase/auth";
import {
  AppleLoginButton,
  GoogleLoginButton,
  TwitterLoginButton,
} from "react-social-login-buttons";

import {
  checkoutMonthlyPlan,
  checkoutHarfYearPlan,
  checkoutAnnuallyPlan,
  getUserProfile,
} from "./utils/index";
import {Link} from "@mui/material";

function App() {
  const theme = createTheme({
    palette: {
      background: {
        default: "#fff",
      },
      primary: {
        main: "#312e81",
        // light: "#ffa2a3",
        // dark: "#a34449",
      },
      neutral: {
        main: "#d79b4a",
      },
      text: {primary: "#000"},
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 768,
        lg: 1025,
        xl: 1536,
      },
    },
  });
  theme.typography.h2 = {
    fontSize: "1.2rem",
    "@media (min-width:600px)": {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.2rem",
    },
  };
  theme.typography.h3 = {
    fontSize: "1.2rem",
    "@media (min-width:600px)": {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.7rem",
    },
  };
  theme.typography.h4 = {
    fontSize: "1.1rem",
    "@media (min-width:600px)": {
      fontSize: "1.3rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5rem",
    },
  };
  theme.typography.h5 = {
    fontSize: "1.0rem",
    "@media (min-width:600px)": {
      fontSize: "1.3rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5rem",
    },
  };
  theme.typography.h6 = {
    fontSize: "1.0rem",
    "@media (min-width:600px)": {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.4rem",
    },
  };
  theme.typography.p = {
    fontSize: "1.0rem",
    "@media (min-width:600px)": {
      fontSize: "1.0rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem",
    },
    fontWeight: 100,
  };

  const [authState, setAuthState] = useState("SignOut");
  const [currentUser, setCurrentUser] = useState(null);
  const [userProfile, setUserProfile] = useState(null);

  const providerGoogle = new GoogleAuthProvider();
  const providerTwitter = new TwitterAuthProvider();
  const providerApple = new OAuthProvider("apple.com");

  const clickLogin = function (provider) {
    signInWithRedirect(auth, provider);
  };
  const clickLogout = async function () {
    signOut(auth)
      .then(() => {
        console.log("ログアウトしました");
      })
      .catch((error) => {
        console.log(`ログアウト時にエラーが発生しました (${error})`);
      });
  };
  const clickCheckoutMonthly = async function () {
    if (currentUser) {
      await checkoutMonthlyPlan(currentUser.uid);
    }
  };
  const clickCheckoutHarfYear = async function () {
    if (currentUser) {
      await checkoutHarfYearPlan(currentUser.uid);
    }
  };
  const clickCheckoutAnnually = async function () {
    if (currentUser) {
      await checkoutAnnuallyPlan(currentUser.uid);
    }
  };

  const CustomButton = styled(Button)({
    backgroundColor: "linear-gradient(106.73deg, #7dd3fc 25%, #5b21b6 130.87%)",
    fontSize: "1.0rem",
    "@media (min-width:600px)": {
      fontSize: "1.5rem",
    },
  });

  //auth
  // useEffect(() => {
  //   onAuthStateChanged(auth, async (user) => {
  //     if (!user) {
  //       // signInAnonymously(auth);
  //       setAuthState("SignOut");
  //       setCurrentUser(null);
  //       setUserProfile(null);
  //     } else {
  //       setAuthState("SignIn");
  //       setCurrentUser(user);
  //       const profile = await getUserProfile(user.uid);
  //       if (!profile) {
  //         // alert("サークリーのアカウントがありません");
  //         setUserProfile(profile);
  //       } else {
  //         setUserProfile(profile);
  //       }
  //     }
  //   });
  // }, [authState]);

  useEffect(() => {
    const unsubscribed = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        // signInAnonymously(auth);
        setAuthState("SignOut");
        setCurrentUser(null);
        setUserProfile(null);
      } else {
        setAuthState("SignIn");
        setCurrentUser(user);
        const profile = await getUserProfile(user.uid);
        if (!profile) {
          // alert("サークリーのアカウントがありません");
          setUserProfile(profile);
        } else {
          setUserProfile(profile);
        }
      }
    });
    return () => {
      unsubscribed();
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <CssBaseline />
        <AppBar
          position="relative"
          sx={{
            background:
              "linear-gradient(106.73deg, #7dd3fc 25%, #5b21b6 130.87%)",
          }}
        >
          <Toolbar>
            {/* <Button variant="contained" color="primary" href="/">
              <Typography
                color="inherit"
                sx={{fontSize: 18, fontWeight: "bold"}}
              >
                サークリー プレミアム会員
              </Typography>
            </Button> */}
            <Link href="/">
              <Box
                component="img"
                sx={{
                  height: 28,
                }}
                alt="header-banner"
                src="https://firebasestorage.googleapis.com/v0/b/circley.appspot.com/o/Circley%2FPremium%2Fheader_banner.png?alt=media&token=226bff08-db9f-4301-9fd9-544976702249"
              />
            </Link>
          </Toolbar>
        </AppBar>
        {/* <main> */}
        <Box sx={{paddingLeft: 2, paddingRight: 2}} component="main">
          {authState === "SignOut" ? (
            <Stack
              sx={{pt: 4}}
              direction="column"
              spacing={2}
              justifyContent="center"
            >
              <Stack
                borderRadius={4}
                sx={{
                  backgroundColor: "#312e81",
                  paddingX: 2,
                  paddingY: 4,
                }}
                direction="column"
                spacing={2}
                justifyContent="flex-start"
              >
                <Typography
                  color="#fff"
                  component="h4"
                  variant="h3"
                  align="left"
                >
                  プレミアム会員登録専用ページ
                </Typography>
                {/* <Box
                  component="img"
                  sx={{
                    width: 120,
                    height: "auto",
                  }}
                  alt="premium-trial-banner"
                  src="https://firebasestorage.googleapis.com/v0/b/circley.appspot.com/o/Circley%2FPremium%2Fpremium_free.png?alt=media&token=b4da5a02-c72c-41b1-a45f-8c4cc3227b40"
                /> */}
                {/* <Stack
                  sx={{width: "100%"}}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box
                    component="img"
                    sx={{
                      width: "40%",
                      maxWidth: 180,
                      height: "auto",
                    }}
                    alt="toppage-girl"
                    src="https://firebasestorage.googleapis.com/v0/b/circley.appspot.com/o/Circley%2FPremium%2Ftoppage_girl.png?alt=media&token=51f69d7b-ff9f-4207-aa53-799cb4386c6b"
                  />
                </Stack> */}
              </Stack>
              <Stack>
                <Typography
                  color="#000"
                  component="h4"
                  variant="h4"
                  align="left"
                >
                  ただいまWebページからの新規会員登録の受付を停止しているよ。アプリ内から会員登録をお願いします。
                </Typography>
              </Stack>
              <Stack
                borderRadius={4}
                sx={{
                  backgroundColor: "#232334",
                  paddingX: 2,
                  paddingY: 4,
                }}
                direction="column"
                spacing={2}
                justifyContent="flex-start"
              >
                <Typography
                  color="#fff"
                  component="h4"
                  variant="h3"
                  align="left"
                >
                  アプリのアカウントにログイン
                </Typography>
                <Stack>
                  <AppleLoginButton onClick={() => clickLogin(providerApple)}>
                    <span>Appleでログイン</span>
                  </AppleLoginButton>

                  <GoogleLoginButton onClick={() => clickLogin(providerGoogle)}>
                    <span>Googleでログイン</span>
                  </GoogleLoginButton>

                  {/* <TwitterLoginButton
                    onClick={() => clickLogin(providerTwitter)}
                  >
                    <span>Twitterでログイン</span>
                  </TwitterLoginButton> */}
                </Stack>
                <Typography
                  color="#fff"
                  component="h4"
                  variant="h6"
                  align="left"
                >
                  （SNSアカウントと連携していない場合、プレミアム会員に登録できません）
                </Typography>
              </Stack>
            </Stack>
          ) : authState === "SignIn" ? (
            <Stack
              sx={{pt: 4}}
              direction="column"
              spacing={2}
              justifyContent="center"
            >
              {userProfile ? (
                <Box
                  borderRadius={4}
                  borderColor="#312e81"
                  border={2}
                  sx={{
                    backgroundColor: "#fff",
                    paddingX: 2,
                    paddingY: 3,
                  }}
                  direction="column"
                  spacing={2}
                  justifyContent="flex-start"
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="flex-start"
                  >
                    <Avatar
                      alt="ai-avatar"
                      src={userProfile.avatar}
                      sx={{width: 52, height: 52}}
                    />
                    <Stack
                      direction="column"
                      spacing={2}
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Typography
                        color="#343434"
                        component="h4"
                        variant="h4"
                        align="left"
                      >
                        {userProfile.name}
                      </Typography>
                      <Typography
                        component="h4"
                        color="#343434"
                        variant="p"
                        align="left"
                        noWrap
                        maxWidth={300}
                      >
                        {userProfile.description}
                      </Typography>
                      <Typography
                        component="h4"
                        color="#343434"
                        variant="h6"
                        align="left"
                      >
                        ステータス：
                        {userProfile.paid_plan === "premium"
                          ? "プレミアム会員に登録中"
                          : userProfile.paid_plan === "premium_trial"
                          ? "プレミアム会員（無料版、機能制限あり）"
                          : "プレミアム会員ではありません"}
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
              ) : null}

              {userProfile ? (
                <Stack>
                  <Typography component="h4" variant="p" align="left">
                    アカウントが正しければ、登録へ進みましょう。 異なる場合は、
                    <a href="/" onClick={clickLogout}>
                      ログアウト
                    </a>
                    して、再度登録したいアカウントを選択します。
                  </Typography>

                  {userProfile.paid_plan === "premium" ? (
                    <CustomButton
                      sx={{
                        marginTop: 4,
                      }}
                      variant="contained"
                      onClick={clickCheckoutMonthly}
                    >
                      <Typography component="h4" variant="h6" align="center">
                        サブスクリプションを管理
                      </Typography>
                    </CustomButton>
                  ) : (
                    <Stack paddingTop={4}>
                      {userProfile.paid_plan === "premium_trial" ? (
                        <Typography component="h4" variant="h6" align="center">
                          プレミアム会員（有料版）へ移行して機能制限を解除できます
                        </Typography>
                      ) : null}

                      <Typography component="h4" variant="h6" align="center">
                        まずはプランを選択します
                      </Typography>
                      <Typography component="h4" variant="h6" align="center">
                        全プラン 3日間無料
                      </Typography>
                      <Stack direction={"column"}>
                        <ButtonBase
                          borderRadius={4}
                          sx={{
                            backgroundColor: "#232334",
                            paddingX: 2,
                            paddingY: 4,
                            margin: 2,
                            alignItems: "center",
                            justifyContent: "flex-start",
                            flex: 1,
                          }}
                          direction="column"
                          spacing={2}
                          onClick={clickCheckoutAnnually}
                        >
                          <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="flex-start"
                          >
                            <Stack>
                              <Box>
                                <Typography
                                  component="h5"
                                  color="#fff"
                                  variant="p"
                                  align="left"
                                  fontSize={"1.2rem"}
                                >
                                  １年プラン
                                </Typography>
                                <Typography
                                  component="h6"
                                  color="#fff"
                                  variant="p"
                                  align="left"
                                  fontSize={"1.3rem"}
                                  sx={{
                                    textDecoration: "line-through",
                                    textDecorationStyle: "double",
                                  }}
                                >
                                  ¥11,760
                                </Typography>
                                <Typography
                                  component="h6"
                                  color="#fff"
                                  variant="p"
                                  align="left"
                                  fontSize={"1.8rem"}
                                >
                                  ¥9,360
                                </Typography>
                                <Typography
                                  component="h6"
                                  color="#fff"
                                  variant="p"
                                  align="left"
                                  fontSize={"0.7rem"}
                                >
                                  今なら20%割引!!
                                </Typography>
                              </Box>
                            </Stack>
                            <Box
                              component="img"
                              sx={{
                                width: 100,
                                height: 50,
                                marginBottom: 2,
                              }}
                              alt="premium-recommend"
                              src="https://firebasestorage.googleapis.com/v0/b/circley.appspot.com/o/Circley%2FPremium%2Fpremium_recommend.png?alt=media&token=e30a0349-ff10-4a8a-a2db-3c295192c7b5"
                            />
                          </Stack>
                          <Box
                            sx={{
                              position: "absolute",
                              right: 10,
                              top: 30,
                            }}
                          >
                            <Typography
                              component="h6"
                              color="#fff"
                              variant="p"
                              align="left"
                              fontSize={"1.1rem"}
                            >
                              ¥780/月
                            </Typography>
                          </Box>
                        </ButtonBase>

                        <ButtonBase
                          borderRadius={4}
                          sx={{
                            backgroundColor: "#565656",
                            paddingX: 2,
                            paddingY: 4,
                            margin: 2,
                            alignItems: "center",
                            justifyContent: "flex-start",
                            flex: 1,
                          }}
                          direction="column"
                          spacing={2}
                          onClick={clickCheckoutHarfYear}
                        >
                          <Stack>
                            <Typography
                              component="h5"
                              color="#fff"
                              variant="p"
                              align="left"
                              fontSize={"1.2rem"}
                            >
                              6ヶ月プラン
                            </Typography>
                            <Typography
                              component="h6"
                              color="#fff"
                              variant="p"
                              align="left"
                              fontSize={"1.8rem"}
                            >
                              ¥5,580
                            </Typography>
                          </Stack>
                          <Box
                            sx={{
                              position: "absolute",
                              right: 10,
                              top: 30,
                            }}
                          >
                            <Typography
                              component="h6"
                              color="#fff"
                              variant="p"
                              align="left"
                              fontSize={"1.1rem"}
                            >
                              ¥930/月
                            </Typography>
                          </Box>
                        </ButtonBase>

                        <ButtonBase
                          borderRadius={4}
                          sx={{
                            backgroundColor: "#BCBCBC",
                            paddingX: 2,
                            paddingY: 4,
                            margin: 2,
                            alignItems: "center",
                            justifyContent: "flex-start",
                            flex: 1,
                          }}
                          direction="column"
                          spacing={2}
                          onClick={clickCheckoutMonthly}
                        >
                          <Stack>
                            <Typography
                              component="h5"
                              color="#fff"
                              variant="p"
                              align="left"
                              fontSize={"1.2rem"}
                            >
                              １ヶ月プラン
                            </Typography>
                            <Typography
                              component="h6"
                              color="#fff"
                              variant="p"
                              align="left"
                              fontSize={"1.8rem"}
                            >
                              ¥980
                            </Typography>
                          </Stack>
                          <Box
                            sx={{
                              position: "absolute",
                              right: 10,
                              top: 30,
                            }}
                          >
                            <Typography
                              component="h6"
                              color="#fff"
                              variant="p"
                              align="left"
                              fontSize={"1.1rem"}
                            >
                              ¥980/月
                            </Typography>
                          </Box>
                        </ButtonBase>
                      </Stack>
                    </Stack>
                  )}

                  <Box>
                    <Stack padding={2}>
                      <Typography component="h4" variant="h4" align="left">
                        よくある質問
                      </Typography>
                    </Stack>

                    <Stack padding={2}>
                      <Typography component="h4" variant="p" align="left">
                        Q. プランの解約はどのようにしたらいいでしょうか？
                      </Typography>
                      <Typography component="h4" variant="p" align="left">
                        A.
                        プレミアム会員は、このページからいつでも好きなタイミングで解約できます。
                      </Typography>
                    </Stack>

                    <Stack padding={2}>
                      <Typography component="h4" variant="p" align="left">
                        Q. 無料期間中に解約できますか？
                      </Typography>
                      <Typography component="h4" variant="p" align="left">
                        A.
                        はい、無料期間中に解約した場合は、一切の料金が発生しません。
                      </Typography>
                    </Stack>

                    <Stack padding={2}>
                      <Typography component="h4" variant="p" align="left">
                        Q. 有料プランを途中で解約した場合、返金されますか？
                      </Typography>
                      <Typography component="h4" variant="p" align="left">
                        A. 途中解約した場合でも、返金はございません。
                      </Typography>
                    </Stack>
                  </Box>
                </Stack>
              ) : (
                <Stack>
                  <Typography component="h4" variant="p" align="left">
                    サークリーのアカウントがありません
                  </Typography>
                  <Typography component="h4" variant="p" align="left">
                    <a href="/" onClick={clickLogout}>
                      ログアウト
                    </a>
                    して、再度登録したいアカウントでログインしてください。
                  </Typography>
                </Stack>
              )}
            </Stack>
          ) : null}

          {/* <div className="App"> */}
          {/* <BrowserRouter>
              <Routes>
                <Route path={`/`} element={<Toppage />} /> */}
          {/* <Route path={`/questions/`} element={<QuestionList />} />
            <Route path="/qa/" element={<Posts />}>
              <Route path=":questionPath" element={<Post />} />
            </Route> */}
          {/* </Routes>
            </BrowserRouter> */}

          {/* </div> */}
        </Box>
        {/* </main> */}

        {/* footer */}
        <Box height={30} />
      </div>
    </ThemeProvider>
  );
}

export default App;
