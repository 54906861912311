import { initializeApp } from "firebase/app";
// 3. Authを使うので、以下をインポートする
import { getAuth, GoogleAuthProvider } from "firebase/auth";
// 4. firestoreを使うので、以下をインポートする
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
// import {connectFunctionsEmulator} from "firebase/functions";

const REGION_TOKYO = "asia-northeast1";

const firebaseConfig = {
  apiKey: "AIzaSyCzt2pkmN-B1jK98irGtprWuhhsBCmcrs0",
  authDomain: "circley.firebaseapp.com",
  databaseURL: "https://circley.firebaseio.com",
  projectId: "circley",
  storageBucket: "circley.appspot.com",
  messagingSenderId: "400881273241",
  appId: "1:400881273241:web:6168f5cfe855c5458d6b08",
  measurementId: "G-9EJ0NL4E4C",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// 4. authとfirebaseそれぞれ実行、インスタンス化
const auth = getAuth(app);
const functions = getFunctions();
functions.region = REGION_TOKYO;
// connectFunctionsEmulator(functions, "localhost", 5001);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);

// exportしてどこからでも使えるようにする
export { auth, functions, provider, db };
