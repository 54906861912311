import {db, auth, functions} from "../firebase";
// import {httpsCallable} from "firebase/functions";
import {
  doc,
  collection,
  addDoc,
  getDoc,
  // getDocs,
  // query,
  // where,
  // orderBy,
  // limit,
  // Timestamp,
  serverTimestamp,
  onSnapshot,
} from "firebase/firestore";

export function getNowDate() {
  return serverTimestamp();
}

export async function checkoutMonthlyPlan(uid) {
  const customerRef = doc(collection(db, "stripe_customers"), uid);
  const checkoutColRef = collection(customerRef, "checkout_sessions");
  const docRef = await addDoc(checkoutColRef, {
    automatic_tax: false,
    tax_rates: ["txr_1NhbDcFMdLVQLV5pOmy6t4ox"],
    price: "price_1NhbEMFMdLVQLV5p3JPy3S2v",
    success_url: window.location.origin,
    cancel_url: window.location.origin,
  });
  onSnapshot(docRef, (snapshot) => {
    const {error, url} = snapshot.data();
    if (error) {
      alert(`An error occured: ${error.message}`);
    }
    if (url) {
      window.location.assign(url);
    }
  });
}

export async function checkoutHarfYearPlan(uid) {
  const customerRef = doc(collection(db, "stripe_customers"), uid);
  const checkoutColRef = collection(customerRef, "checkout_sessions");
  const docRef = await addDoc(checkoutColRef, {
    automatic_tax: false,
    tax_rates: ["txr_1NhbDcFMdLVQLV5pOmy6t4ox"],
    price: "price_1NrsSTFMdLVQLV5pp2qCqko8",
    success_url: window.location.origin,
    cancel_url: window.location.origin,
  });
  onSnapshot(docRef, (snapshot) => {
    const {error, url} = snapshot.data();
    if (error) {
      alert(`An error occured: ${error.message}`);
    }
    if (url) {
      window.location.assign(url);
    }
  });
}

export async function checkoutAnnuallyPlan(uid) {
  const customerRef = doc(collection(db, "stripe_customers"), uid);
  const checkoutColRef = collection(customerRef, "checkout_sessions");
  const docRef = await addDoc(checkoutColRef, {
    automatic_tax: false,
    tax_rates: ["txr_1NhbDcFMdLVQLV5pOmy6t4ox"],
    price: "price_1NrsZxFMdLVQLV5p4m1v96t6",
    success_url: window.location.origin,
    cancel_url: window.location.origin,
  });
  onSnapshot(docRef, (snapshot) => {
    const {error, url} = snapshot.data();
    if (error) {
      alert(`An error occured: ${error.message}`);
    }
    if (url) {
      window.location.assign(url);
    }
  });
}

export async function getUserProfile(uid) {
  const userRef = doc(db, "users", uid);
  const document = await getDoc(userRef);
  if (document.exists) {
    const user = document.data();
    return user;
  } else {
    return null;
  }
}
